import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import { web3 } from '../../store/web3';
import { networkId, message } from '../../store/config';
import { login } from '../../store/actions/Auth';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            address: '',
            isShareModal: false,
        };
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress })
    }

    connectWallet = async () => {
        if (typeof window.ethereum === 'undefined') {
            EventBus.publish('error', `Please Install Metamask!!!`);
            return;
        }

        web3.eth.net.getId(async (err, netId) => {
            if (netId != networkId) {
                EventBus.publish('info', message);
                return;
            }
            let address = (await web3.currentProvider.enable())[0];
            this.props.login(address);
            this.setState({ address });
        });
    };

    toggleShareModal = () => this.setState({ isShareModal: !this.state.isShareModal });

    render() {
        let { address, isOpen, isShareModal } = this.state;
        let { sticky, publicAddress } = this.props;

        return (
            <div className={`mp-club-nav ${sticky && 'sticky-nav'}`}>
                <nav className='navbar navbar-expand-lg sidenav' id="sidenav-1" data-mdb-hidden="false">
                    <div className='container-fluid'>
                        <div className="inner-container">
                            {/* <Link className='navbar-brand' to='/'><img src={require('../../static/images/landing/logo.png')} alt='' /></Link> */}
                            <button onClick={() => this.setState({ isOpen: true })} className='navbar-toggler' type='button' data-toggle='collapse'
                                data-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false'
                                aria-label='Toggle navigation' aria-haspopup="true">
                                <i class='fa fa-bars' aria-hidden='true'></i>
                            </button>
                            <div className='collapse navbar-collapse nav-links' id='navbarSupportedContent'>
                                <ul className='navbar-nav sidenav-menu'>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='#home'>Home</HashLink>
                                    </li>
                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='#about'>About</HashLink>
                                    </li> */}
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='#rewards'>Rewards</HashLink>
                                    </li>
                                    <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='#tokenomics'>Tokenomics</HashLink>
                                    </li>
                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#leaderboard'>Leaderboard</HashLink>
                                    </li> */}
                                    <li className='nav-item'>
                                        <a className='nav-link' smooth href='https://webgl.modernpokerclub.com/' target="_blank">Play Game</a>
                                    </li>

                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#marketplace'>Marketplace</HashLink>
                                    </li> */}
                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#mint-your-nft-in-three-steps'>How to Mint</HashLink>
                                    </li> */}
                                    {/* <li className='nav-item'>
                                        <HashLink className='nav-link' smooth to='/#faqs'>FAQs</HashLink>
                                    </li> */}
                                    {/* <li className='nav-item'>
                                        <a href='https://white-paper-poker-club.s3.us-east-2.amazonaws.com/Whitepaper-MPC.pdf' className='nav-link' target="_blank">Whitepaper</a>
                                    </li> */}
                                    {/* <li className='nav-item'>
                                        <button className='nav-link' onClick={this.toggleShareModal}>Bridge</button>
                                    </li> */}

                                    {/* <li className='nav-item nav-img'>
                                        <img className="right-img" src={require('../../static/images/landing/hcl_official_sponsors_logo.png')} alt='' />
                                    </li> */}
                                </ul>


                                <button onClick={this.connectWallet} className="nav-btn btn-style-one desktop-btn" type="button">
                                    <span>
                                        {publicAddress == '' || publicAddress == null
                                            ? 'Connect wallet'
                                            : publicAddress && publicAddress.substring(0, 5) + '.....' + publicAddress.substring(37, publicAddress.length)
                                        }
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>

                {/* --------------- Bridge MODAL--------------- */}
                <Modal isOpen={isShareModal} toggle={this.toggleShareModal} className={`main-modal bridge-modals`}>
                    <ModalHeader toggle={this.toggleShareModal}></ModalHeader>
                    <ModalBody>
                        <div className='content-area'>
                            <div className="top-area">
                                <div className="logo-area">
                                    <img src={require('../../static/images/new-landing/logo-banner.png')} alt='' />
                                </div>
                                <div className="right-area">
                                    <img src={require('../../static/images/fish-modal.png')} alt='' />
                                </div>
                            </div>
                            <div className="bridge-section">
                                <div className="top-area">
                                    <h2>BRIDGE</h2>
                                </div>
                                <div className="wallet-area">
                                    <div className="group-from">
                                        <label>Enter Phantom Wallet</label>
                                        <input type="text" placeholder="Enter Phantom Wallet" value="7mF4vYyB39rgTW2iaCrdqE1p2XoMnLJjsNbPRgBh3p8o" />
                                    </div>
                                </div>
                                <div className="nft-selection-area">
                                    <h4>TOTAL NFT : <span>4</span></h4>
                                    <div className="nft-inner-area">
                                        <div className="nft-box">
                                            <input type="checkbox" id="myCheckbox1" />
                                            <label for="myCheckbox1"><img src={require('../../static/images/nft-1.png')} alt='' /></label>
                                        </div>
                                        <div className="nft-box">

                                            <input type="checkbox" id="myCheckbox2" />
                                            <label for="myCheckbox2"><img src={require('../../static/images/nft-2.png')} alt='' /></label>
                                        </div>
                                        <div className="nft-box">

                                            <input type="checkbox" id="myCheckbox3" />
                                            <label for="myCheckbox3"><img src={require('../../static/images/nft-3.png')} alt='' /></label>
                                        </div>
                                        <div className="nft-box">

                                            <input type="checkbox" id="myCheckbox4" />
                                            <label for="myCheckbox4"><img src={require('../../static/images/nft-4.png')} alt='' /></label>
                                        </div>
                                    </div>
                                    <div className="btn-area">
                                        <button className="btn-style-two">Confirm</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}

const mapDispatchToProps = {
    login
};

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);