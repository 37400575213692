import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import Countdown from 'react-countdown';
import OwlCarousel from 'react-owl-carousel';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { toggleLoader } from "../../store/actions/Auth";
import { ICO, ICOAddress } from '../../store/contract/index';
import { networkId, message, opensea } from "../../store/config";


class MPokerClub extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stage: 0,
            sticky: false,
            isNetwork: true,
            isPreSale: false,
            rate: '',
            nftPrice: '',
            quantity: 0,
            max: '',
            min: 0,
            mintItems: '',
            availableItems: '',
            contributions: '',
            openingTime: '',
            closingTime: '',
            address: localStorage.getItem('publicAddress'),
        };
    };

    async componentDidMount() {
        let { publicAddress } = this.props;
        this.setState({ address: publicAddress }, () => this.checkEthereum());
        this.checkEthereum();

        // Attach the onload event with a delay
        // window.onload = () => {
        //     setTimeout(this.handleEnterWebsite, 1500); // Delay of 1500ms
        // };
    };

    // componentWillUnmount() {
    //     window.onload = null;
    // }


    // handleEnterWebsite = () => {
    //     window.scrollTo(0, 0);
    //     console.log('**********Website entered, scrolling to top.');
    // };

    checkEthereum = async () => {
        let { address } = this.state;
        if (typeof window.ethereum !== "undefined") {
            // check network
            web3.eth.net.getId((err, netId) => {
                if (netId != networkId) this.setState({ isNetwork: false });
                else this.setState({ isNetwork: true });
            });
            let rate = await (ICO.methods.rate().call());
            let cap = await (ICO.methods.cap().call());
            let minted = await (ICO.methods.minted().call());
            let maximum = await (ICO.methods.investorHardCap().call());
            let time = await (ICO.methods.openingTime().call());
            let closingTime = await (ICO.methods.closingTime().call());
            let stage = await (ICO.methods.stage().call());
            let nftPrice = await (web3.utils.fromWei(rate.toString(), 'ether'));
            let availableItems = parseFloat(cap);
            let mintItems = parseFloat(minted);
            let openingTime = parseFloat(time);
            let max = parseFloat(maximum);
            if (address !== '' && address !== null && address !== undefined) {
                let contribution = await (ICO.methods.contributions(address).call());
                let contributions = parseFloat(contribution);
                this.setState({ contributions });
            };
            this.setState({ stage, rate, nftPrice, max, openingTime, closingTime, availableItems, mintItems });
        } else this.setState({ metamaskNull: true });
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress });
    };

    increaseQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(quantity) + parseFloat(contributions));
        if (limit < max) {
            quantity = parseFloat(quantity) + 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't exceed quantity then maximum limit of ${max}`);
    };

    decreaseQuantity = () => {
        let { address, quantity, min } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        if (quantity > min) {
            quantity = parseFloat(quantity) - 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't decrease quantity to minimum`);
    };

    maxQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(max) - parseFloat(contributions));
        if (quantity < limit) {
            let quantity = parseFloat(max) - parseFloat(contributions);
            this.setState({ quantity })
        }
        else EventBus.publish('info', `You already have maximum limit of quantity`);
    };

    handleQuantity = (number) => {
        let { address, quantity, min, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let value = parseFloat(number);
        if (quantity > 0) this.setState({ quantity: 0 });

        if (value > min && value <= max) {
            let limit = (value + parseFloat(contributions));
            if (limit <= max) this.setState({ quantity: value });
            else EventBus.publish('info', `You can't exceed quantity then maximum limit of ${max}`);
        }
        else {
            if (value > max) EventBus.publish('info', `You are exceeding max amount of ${max}`);
        }
    };

    mintNFT = async () => {
        try {
            let { stage, rate, quantity, address, availableItems } = this.state;

            if (address == '' || address == null) {
                EventBus.publish('error', `Connect to Metamask`);
                return;
            };
            if (!validate(address, 'ETH')) {
                EventBus.publish('error', `Please provide valid ETH Address`);
                return;
            };
            if (quantity == 0) {
                EventBus.publish('error', `Please Enter the Quantity`);
                return;
            };

            const value = rate * quantity;
            const from = (await web3.currentProvider.enable())[0];
            const balance = await web3.eth.getBalance(from);
            const mintItems = await (ICO.methods.minted().call());

            if (mintItems >= availableItems) {
                EventBus.publish('error', `Sale has been Ended`);
                return;
            };

            if (value > balance) {
                EventBus.publish('error', `Your have insufficient ETH Balance`);
                return;
            };

            /** Mint NFT **/
            this.props.toggleLoader({ message: 'Minting in Progress...', status: true });
            await web3.eth.sendTransaction({
                from,
                value,
                to: ICOAddress,
                data: ICO.methods.buyNFT(address, quantity).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    const mintItems = await (ICO.methods.minted().call());
                    const contributions = await (ICO.methods.contributions(address).call());
                    let time = await (ICO.methods.openingTime().call());
                    let openingTime = parseFloat(time);

                    this.setState({ mintItems, contributions, quantity: 0, openingTime, isPreSale: false });
                    this.props.toggleLoader({ status: false });
                    EventBus.publish('success', `NFT Minted successfully`);
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    handleReload = () => {
        this.setState({ isPreSale: true }, () => this.checkEthereum());
        EventBus.publish('info', "Sale is Started");
    };

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            this.setState({ isPreSale: true }, () => this.checkEthereum());
            return <h2 className="text-white">Sale is Started</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <p>Days</p>
                    </div>
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <p>Hrs</p>
                    </div>
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <p>Mins</p>
                    </div>
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <p>Sec</p>
                    </div>
                </div>
            );
        }
    };

    onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) this.setState({ sticky: true });
        if (pageYOffset < 20) this.setState({ sticky: false });
    };

    render() {

        const owl_option = {
            margin: 20,
            nav: true,
            items: 1,
            loop: true,
            autoplay: true,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 1,
                },
                700: {
                    items: 1,
                },
                1000: {
                    items: 1,
                }
            },
        };

        let { address, isNetwork, isPreSale, quantity, nftPrice, mintItems, availableItems, openingTime,
            metamaskNull, max, contributions, sticky } = this.state;

        return (
            <div className="mp-club-page" onWheel={this.onScroll}>
                <Navbar sticky={sticky} />
                {/* Banner Section */}
                <section id="home" className="banner-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/banner-bg.png")})` }}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="banner-text col-lg-6 col-md-12">
                                <div className="banner-inner">
                                    <div className="title-icon">
                                        <div className="img-box">
                                            <img className="left-img" src={require('../../static/images/new-landing/logo-banner.png')} alt='' />
                                        </div>
                                    </div>
                                    {/* <p>Join the exclusive Poker Club and be a part of this exciting new journey.</p> */}
                                    {/* <div className="countdown-timer timer-coutdwon">
                                        {!isPreSale &&
                                            <div className="timer-inner">
                                                {openingTime &&
                                                    <Countdown date={Date.now() + parseInt((openingTime * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                }
                                            </div>
                                        }
                                    </div> */}

                                    <div className="btn-group">
                                        <a className='banner-btn'  target="_blank" smooth href='https://webgl.modernpokerclub.com/'>
                                            <i className="play-icon"><img src={require('../../static/images/new-landing/play-icon.png')} alt='' /></i>
                                            <span>play game</span>
                                            <i className="poker-icon"><img src={require('../../static/images/new-landing/poker-icon.png')} alt='' /></i>
                                        </a>
                                        {/* <a href="http://discord.gg/WDNCvU9X" className='btn-style-two' target="_blank">JOIN OUR DISCORD TO GET STARTED</a> */}
                                    </div>

                                    {/* <div className="text-box">
                                        <h4>MINTING CALENDAR</h4>
                                        <p>MINT 1 | 22nd Jul | 400 available | 0.15 ETH<br />
                                            MINT 2 | 29th Jul | 400 available | 0.16 ETH<br />
                                            MINT 3 | 5th Aug | 400 available | 0.17 ETH<br />
                                            MINT 4 | 12th Aug |  400 available | 0.18 ETH<br />
                                            MINT 5 | 19th Aug | 400 available | 0.19 ETH<br />
                                            MINT 6 | 26th Aug | All remaining | 0.20 ETH</p>
                                    </div> */}
                                </div>
                            </div>
                            <div className="banner-img col-lg-6 col-md-12">
                                <div className="img-inner">
                                    <img src={require('../../static/images/new-landing/banner-left-img.png')} alt='' />
                                    {/* <div className="btn-group">
                                        <a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/landing/opensea.png')} alt='' /><span>MPC Collection</span></a>
                                        <a href="https://x.com/ModernPokerClub" className="social-link" target="_blank"><img src={require('../../static/images/landing/twitter.png')} alt='' /><span>Twitter</span></a>
                                        <a href="http://discord.gg/WDNCvU9X" className="social-link" target="_blank"><img src={require('../../static/images/landing/discord.png')} alt='' /><span>Discord</span></a>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* About Section */}
                <section id="about" className="about-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/about-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/about-title.png')} alt='' /></h2>
                                </div>

                                <div className="about-text-area">
                                    <div className="about-inner">
                                        <div className="left-area">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/about-img.png')} alt='' />
                                            </div>
                                            <div className="social-links">
                                                <a href="https://x.com/ModernPokerClub" className="social-link" target="_blank"><img src={require('../../static/images/new-landing/twitter-icon.png')} alt='' /></a>
                                                {/* <a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/landing/opensea.png')} alt='' /><span>MPC Collection</span></a> */}
                                                <a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/new-landing/telegram-icon.png')} alt='' /></a>
                                                <a href="http://discord.gg/WDNCvU9X" className="social-link" target="_blank"><img src={require('../../static/images/new-landing/discord-icon.png')} alt='' /></a>
                                            </div>
                                        </div>
                                        <div className="right-area">
                                            <div className="text-box">
                                                <p>ModernPokerClub is a service that offers a play-to-earn poker card game where players discover a new style of poker with ModernPokerClub’s evolving brand of faction play, where you’ll strategize with faction mates to conquer your opponents, adorn the faction crown and reap the rewards. The game is built to fundamentally change how games work; using Solana technology to bring true digital ownership to players, as well as provide the means to earn. The $MPC token is designed to be the core currency of ModernPokerClub, fuelling the ecosystem that empowers players to earn and sell Non-Fungible Tokens (NFTs).</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Become Section */}
                <section id="become" className="become-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/become-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/become-title.png')} alt='' /></h2>
                                </div>

                                <div className="become-box-area">
                                    <div className="become-area">
                                        <div className="become-box become-box-1">
                                            <div className="top-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-1.png')} alt='' />
                                            </div>
                                            <div className="bottom-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-1-1.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>A rich poker player willing to splash and go wild </p>
                                            </div>
                                        </div>
                                        <div className="become-box become-box-2">
                                            <div className="top-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-2.png')} alt='' />
                                            </div>
                                            <div className="bottom-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-2-1.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>A professional poker player</p>
                                            </div>
                                        </div>
                                        <div className="become-box become-box-3">
                                            <div className="top-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-3.png')} alt='' />
                                            </div>
                                            <div className="bottom-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-3-1.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>A novice poker player</p>
                                            </div>
                                        </div>
                                        <div className="become-box become-box-4">
                                            <div className="top-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-4.png')} alt='' />
                                            </div>
                                            <div className="bottom-img-area">
                                                <img src={require('../../static/images/new-landing/become-img-4-1.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>Despite Knowing THE Odds still makes bad decisions</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Become Section */}
                <section id="get-started" className="get-started-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/get-started-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/get-started-title.png')} alt='' /></h2>
                                </div>

                                <div className="get-started-area">
                                    <div className="get-started-inner">
                                        <div className="get-started-box">
                                            <div className="text-box">
                                                <p>Create phantom wallet</p>
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-1.png')} alt='' />
                                            </div>
                                            <div className="step-box">
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                                <h4>step 1</h4>
                                            </div>
                                        </div>
                                        <div className="get-started-box">
                                            <div className="text-box">
                                                <p>Purchase our ticket from Raydium</p>
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-2.png')} alt='' />
                                            </div>
                                            <div className="step-box">
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                                <h4>step 3</h4>
                                            </div>
                                        </div>
                                        <div className="get-started-box">
                                            <div className="text-box">
                                                <p>start bluffing</p>
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-3.png')} alt='' />
                                            </div>
                                            <div className="step-box">
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                                <h4>step 5</h4>
                                            </div>
                                        </div>
                                        <div className="get-started-box">
                                            <div className="text-box">
                                                <p>spend and trade</p>
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-4.png')} alt='' />
                                            </div>
                                            <div className="step-box">
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                                <h4>step 7</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="get-started-inner style-two">
                                        <div className="get-started-box get-started-box-5">
                                            <div className="step-box">
                                                <h4>step 2</h4>
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-5.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>Get Solana in your wallet.</p>
                                            </div>
                                        </div>
                                        <div className="get-started-box get-started-box-6">
                                            <div className="step-box">
                                                <h4>step 4</h4>
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-6.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>Buy NFT</p>
                                            </div>
                                        </div>
                                        <div className="get-started-box get-started-box-7">
                                            <div className="step-box">
                                                <h4>step 6</h4>
                                                <img src={require('../../static/images/new-landing/arrow-top.png')} alt='' />
                                            </div>
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/timeline-step-7.png')} alt='' />
                                            </div>
                                            <div className="text-box">
                                                <p>play to earn</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Rewards Section */}
                <section id="rewards" className="rewards-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/rewards-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/rewards-title.png')} alt='' /></h2>
                                </div>

                                <div className="rewards-box-area">
                                    <div className="rewards-area">
                                        <div className="text-box">
                                            <p>To catalyze competitive play and stimulate engagement, players can earn meaningful rewards, including the Solana $MPC token, by simply playing the game. The ability to earn tangible rewards serves as the key ingredient to supercharge our future growth. $MPC tokens are designed to exist at the heart of the economy, being used by players to purchase time and NFTs within our club. By including $MPC in the key facets of the ModernPokerClub experience, it creates a sustainable ecosystem where players have the economic freedom to earn, and
                                                contribute to the economy.</p>
                                        </div>
                                        <div className="img-box">
                                            <img src={require('../../static/images/new-landing/rewards-img.png')} alt='' />
                                        </div>
                                    </div>
                                    <div className="prize-pool-box">
                                        <div className="prize-box">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/prize-icon.png')} alt='' />
                                            </div>
                                            <h4>Tournament prize pool distribution</h4>
                                        </div>
                                    </div>
                                    <div className="prizes-area">
                                        <div className="prizes-box">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/awards-1.png')} alt='' />
                                            </div>
                                            <div className="progress-area">
                                                <div className="precentage-area">
                                                    <span>1st</span>
                                                    30%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes-box">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/awards-2.png')} alt='' />
                                            </div>
                                            <div className="progress-area">
                                                <div className="precentage-area">
                                                    <span>2nd</span>
                                                    20%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes-box">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/awards-3.png')} alt='' />
                                            </div>
                                            <div className="progress-area">
                                                <div className="precentage-area">
                                                    <span>3rd</span>
                                                    15%
                                                </div>
                                            </div>
                                        </div>
                                        <div className="prizes-box prizes-box-four">
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/awards-4.png')} alt='' />
                                                <p>35% of Tournament prize pools will be distributed to the winning faction participants</p>
                                            </div>
                                            <div className="progress-area">
                                                <div className="precentage-area">
                                                    <span>1st</span>
                                                    35%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* tokenomics Section */}
                <section id="tokenomics" className="tokenomics-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/tokenomics-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/tokenomics-title.png')} alt='' /></h2>
                                </div>

                                <div className="tokenomics-box-area">
                                    <div className="left-area">
                                        <div className="img-box">
                                            <img src={require('../../static/images/new-landing/tokenomics-left.png')} alt='' />
                                        </div>
                                    </div>
                                    <div className="right-area">
                                        <div className="img-box">
                                            <img src={require('../../static/images/new-landing/tokenomics-right.png')} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >

                {/* Buy Section */}
                <section id="buysec" className="buy-sec" style={{ backgroundImage: `url(${require("../../static/images/new-landing/buy-sec.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2><img src={require('../../static/images/new-landing/buy-title.png')} alt='' /></h2>
                                </div>

                                <div className="buysec-box-area">
                                    <div className="left-area">
                                        <div className="img-box">
                                            <img src={require('../../static/images/new-landing/buy-left-img.png')} alt='' />
                                        </div>
                                    </div>
                                    <div className="right-area">
                                        <div className="heading-box">
                                            <h3>BUy now</h3>
                                            {/* <div className='form-box'>
                                                <iframe src="https://raydium.io/swap/?inputMint=sol&outputMint=2GCKQBYot4uFyauVJvcZhiQP8gXgiNQJuYVAo6SVeUti" height="200" width="300" title="Iframe Example"></iframe>
                                            </div> */}
                                            <div className="img-box">
                                                <img src={require('../../static/images/new-landing/swap-img.png')} alt='' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* Join Section */}
                    <div id="joinsec" className="join-sec">
                        <div className="auto-container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="sec-title text-center">
                                        <h2><img src={require('../../static/images/new-landing/join-title.png')} alt='' /></h2>
                                    </div>

                                    <div className="join-box-area">
                                        <div className="img-box">
                                            <img src={require('../../static/images/new-landing/join-img.png')} alt='' />
                                            <div className="social-links">
                                                <a href="https://x.com/ModernPokerClub" className="social-link" target="_blank"><img src={require('../../static/images/new-landing/twitter-icon.png')} alt='' /></a>
                                                {/* <a href="https://opensea.io/collection/modernpokerclub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/landing/opensea.png')} alt='' /><span>MPC Collection</span></a> */}
                                                <a href="https://t.me/ModernPokerClub" className="social-link opensea-link" target="_blank"><img src={require('../../static/images/new-landing/telegram-icon.png')} alt='' /></a>
                                                <a href="http://discord.gg/WDNCvU9X" className="social-link" target="_blank"><img src={require('../../static/images/new-landing/discord-icon.png')} alt='' /></a>
                                                <a href="https://www.youtube.com/@modernpokerclub7637" className="social-link" target="_blank"><img src={require('../../static/images/new-landing/youtube-icon.png')} alt='' /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >


                {/* Minting Section */}
                {/* < section className="minting-sec" id="mint" style={{ backgroundImage: `url(${require("../../static/images/landing/mint-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                {isNetwork && openingTime &&
                                    <div className="sec-title">
                                        {isPreSale
                                            ? <Fragment>
                                                {mintItems < availableItems
                                                    ? <h2>Minting</h2>
                                                    : <h2>Minted</h2>
                                                }
                                            </Fragment>
                                            : <h2>Launch In</h2>
                                        }
                                    </div>
                                }
                            </div>
                            {isNetwork && !metamaskNull
                                ? <div className={`minting-form col-lg-6 offset-lg-3 col-md-12`}>
                                    {openingTime == ''
                                        ? <div className="countdown-timer">
                                            <h1 className="text-white">Loading!!!</h1>
                                            <br />
                                            <i className="fa fa-spinner fa-pulse loader-mint" aria-hidden="true" />
                                        </div>
                                        : <Fragment>
                                            {isPreSale
                                                ? <Fragment>
                                                    {mintItems < availableItems
                                                        ? <div className="minting-inner">
                                                            <form className="minting">
                                                                {contributions == max
                                                                    ? <div className="countdown-timer">
                                                                        <h4 className="text-white">You have minted maximum quantity of NFT's</h4>
                                                                        <br />
                                                                    </div>
                                                                    : < Fragment>
                                                                        <div className="group-form">
                                                                                <input type="text" name="price" value={`Total availabe NFT’s:`} disabled />
                                                                                <span>{availableItems - mintItems}</span>
                                                                                </div>
                                                                        <div className="group-form">
                                                                                <input type="text" name="price" value={`Total minted NFT’s:`} disabled />
                                                                                <span>{mintItems}</span>
                                                                                </div>
                                                                        <div className="group-form">
                                                                            <input type="text" name="price" value={`Price per NFT:`} disabled />
                                                                            <span>{nftPrice} ETH</span>
                                                                        </div>
                                                                        <div className="group-form">
                                                                            <input type="text" name="price" value={`Connected Address`} disabled />
                                                                            <span>{address && address.substring(0, 7) + '.....' + address.substring(35, address.length)}</span>
                                                                        </div>
                                                                        <div className="group-form number-form">
                                                                            <input type="number" name="number" onChange={(e) => this.handleQuantity(e.target.value)} value={quantity > 0 && quantity} placeholder="Enter quantity" />
                                                                            <button onClick={this.decreaseQuantity} type="button" className="count-btn minus-btn"> </button>
                                                                            <button onClick={this.increaseQuantity} type="button" className="count-btn plus-btn"> </button>
                                                                            <button onClick={this.maxQuantity} type="button" className="max-btn btn-style-two">Max</button>
                                                                        </div>
                                                                        <div className="group-form">
                                                                                <input type="text" name="Tprice" value={`Total price: ${(nftPrice * quantity).toFixed(2)} ETH`} disabled />
                                                                                </div>
                                                                        <div className="group-form">
                                                                            <button onClick={this.mintNFT} className="btn-style-two" type="button">MINT NOW</button>
                                                                        </div>
                                                                    </Fragment>
                                                                }
                                                            </form>
                                                        </div>
                                                        : <div className="countdown-timer">
                                                            <h2 className="text-white">NFT’s Sold!!!</h2>
                                                            <br />
                                                            <a href={`${opensea}collection/`} target="_blank" className="btn-style-two">View on OpenSea</a>
                                                        </div>
                                                    }
                                                </Fragment>
                                                : <div className="countdown-timer timer-coutdwon">
                                                    <div className="timer-inner">
                                                        {openingTime &&
                                                            <Countdown date={Date.now() + parseInt((openingTime * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </Fragment>
                                    }
                                </div>
                                : <div className="minting-form col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                                    <div className="countdown-timer">
                                        <h2 className="text-white">{metamaskNull ? 'Please install metamask extension!' : message}</h2>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section > */}

                {/* <Footer /> */}

            </div >
        );
    }
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(MPokerClub);